<template>
  <div class="px-md-14">
    <div>
      <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
        <div class="px-6 pt-8 pb-md-4 pb-8 px-sm-8">
          <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">Brokerage Calculator</p>

          <v-row no-glutters>
            <v-col cols="12" md="3">
              <p class="mb-0 title font-weight-regular">Total quantity</p>
              <v-text-field type="number" hide-spin-buttons @keyup="setAllfun()" dense v-model="intraday_q1" block flat solo background-color="#ffffff" max="10000000" min="1" class="search-field calc-field elevation-0 mr-md-3 rounded-pill caption mb-3" label="Enter quantity" hide-details>
                <template #prepend-inner>
                  <div class="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                      <g clip-path="url(#clip0_1116_9811)">
                        <path d="M8 7.02304L14 3.68904L8.486 0.626041C8.33735 0.543381 8.17008 0.5 8 0.5C7.82992 0.5 7.66265 0.543381 7.514 0.626041L2 3.68904L8 7.02304Z" fill="#666666" />
                        <path d="M9 8.75488V16.0879L14.486 13.0399C14.6418 12.9532 14.7716 12.8265 14.862 12.6729C14.9524 12.5192 15 12.3441 15 12.1659V5.42188L9 8.75488Z" fill="#666666" />
                        <path d="M7 8.75488L1 5.42188V12.1659C0.999993 12.3441 1.04764 12.5192 1.13802 12.6729C1.22839 12.8265 1.3582 12.9532 1.514 13.0399L7 16.0879V8.75488Z" fill="#666666" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1116_9811">
                          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <p class="mb-0 title font-weight-regular">Buy value</p>
              <v-text-field type="number" hide-spin-buttons @keyup="setAllfun()" dense v-model="intraday_buy1" block flat solo background-color="#ffffff" max="10000000000" min="0" class="search-field calc-field elevation-0 mr-md-3 rounded-pill caption mb-3" label="Enter price" hide-details>
                <template #prepend-inner>
                  <div class="mr-1">
                    <span class="title">₹</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <p class="mb-0 title font-weight-regular">Sell value</p>
              <v-text-field type="number" hide-spin-buttons @keyup="setAllfun()" dense v-model="intraday_sell1" block flat solo background-color="#ffffff" max="10000000000" min="0" class="search-field calc-field elevation-0 mr-md-3 rounded-pill caption mb-3" label="Enter price" hide-details>
                <template #prepend-inner>
                  <div class="mr-1">
                    <span class="title">₹</span>
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <p class="mb-0 title font-weight-regular">Brokerage type</p>

              <v-tooltip top color="#000">
                <template v-slot:activator="{on, attrs}">
                  <div v-bind="attrs" v-on="on">
                    <v-switch @change="setBrockerage" v-model="brok_type" inset :label="`In ${brok_type ? 'percentage %' : 'flat ₹'}`"></v-switch>
                  </div>
                </template>
                <span>Switch to {{ brok_type ? "percentage %" : "flat ₹" }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>

        <v-card class="elevation-0" color="transparent">
          <v-tabs @change="setAllfun()" fixed-tabs color="#000000" v-model="segment" dense class="calctabs">
            <v-tab dense v-for="(s, j) in ['Equity', 'F&O', 'Currency', 'Commodity']" :key="j" class="text-none text-start min-w-fit">
              <span class="text-center font-weight-medium tab-txt title">{{ s }}</span></v-tab
            >
            <v-tabs-slider color="#000000"></v-tabs-slider>
          </v-tabs>
          <v-divider></v-divider>
          <v-card class="elevation-0 brk-scr-crd cust-scroll" color="transparent">
            <div class="px-4 px-sm-6 px-md-10 py-6 cnt-brk">
              <div v-for="(c, k) in brkcalcdata" :key="k">
                <v-row :class="c.type != 'h' ? 'py-3' : ''">
                  <v-col v-if="c.name" :class="c.class" class="fs-14 font-weight-regular">{{ c.name }}</v-col>
                  <v-col v-if="segment == 0" :class="c.type == 'h' ? 'text-center' : 'text-right'">
                    <v-text-field
                      v-if="c.type == 'f'"
                      reverse
                      block
                      flat
                      solo
                      background-color="#ffffff"
                      class="calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                      hide-details
                      dense
                      v-model="brok_per1"
                      value="0.03"
                      hide-spin-buttons
                      @keyup="intraday1()"
                      type="number"
                      min="0"
                    ></v-text-field>
                    <span v-else class="txt-black pr-2" :class="c.type == 'h' ? 'fs-18 font-weight-medium' : 'fs-14 font-weight-light'">{{ c.v1 != "-" ? setNumeric(c.v1) : "- -" }}</span>
                  </v-col>
                  <v-col v-if="segment == 1" :class="c.type == 'h' ? 'text-center' : 'text-right'">
                    <v-text-field
                      v-if="c.type == 'f'"
                      reverse
                      block
                      flat
                      solo
                      background-color="#ffffff"
                      class="calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                      hide-details
                      dense
                      v-model="brok_per3"
                      value="0.03"
                      hide-spin-buttons
                      @keyup="intraday3()"
                      type="number"
                      min="0"
                    ></v-text-field>
                    <span v-else class="txt-black pr-2" :class="c.type == 'h' ? 'fs-18 font-weight-medium' : 'fs-14 font-weight-light'">{{ c.v3 != "-" ? setNumeric(c.v3) : "- -" }}</span>
                  </v-col>
                  <v-col v-if="segment == 2" :class="c.type == 'h' ? 'text-center' : 'text-right'">
                    <v-text-field
                      v-if="c.type == 'f'"
                      reverse
                      block
                      flat
                      solo
                      background-color="#ffffff"
                      class="calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                      hide-details
                      dense
                      v-model="brok_per5"
                      value="0.03"
                      hide-spin-buttons
                      @keyup="intraday5()"
                      type="number"
                      min="0"
                    ></v-text-field>
                    <span v-else class="txt-black pr-2" :class="c.type == 'h' ? 'fs-18 font-weight-medium' : 'fs-14 font-weight-light'">{{ c.v5 != "-" ? setNumeric(c.v5) : "- -" }}</span>
                  </v-col>
                  <v-col v-if="segment == 3" :class="c.type == 'h' ? 'text-center' : 'text-right'">
                    <v-text-field
                      v-if="c.type == 'f'"
                      reverse
                      block
                      flat
                      solo
                      background-color="#ffffff"
                      class="calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                      hide-details
                      dense
                      v-model="brok_per7"
                      value="0.03"
                      hide-spin-buttons
                      @keyup="intraday7()"
                      type="number"
                      min="0"
                    ></v-text-field>
                    <span v-else class="txt-black pr-2" :class="c.type == 'h' ? 'fs-18 font-weight-medium' : 'fs-14 font-weight-light'">{{ c.v7 != "-" ? setNumeric(c.v7) : "- -" }}</span>
                  </v-col>

                  <v-col v-if="c.name" :class="c.class" class="fs-14 font-weight-regular">{{ c.name }}</v-col>
                  <v-col v-if="segment == 0" :class="c.type == 'h' ? 'text-center' : 'text-right'">
                    <v-text-field
                      v-if="c.type == 'f'"
                      reverse
                      block
                      flat
                      solo
                      background-color="#ffffff"
                      class="calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                      hide-details
                      dense
                      v-model="brok_per2"
                      value="0.03"
                      hide-spin-buttons
                      @keyup="intraday2()"
                      type="number"
                      min="0"
                    ></v-text-field>
                    <span v-else class="txt-black pr-2" :class="c.type == 'h' ? 'fs-18 font-weight-medium' : 'fs-14 font-weight-light'">{{ c.v2 != "-" ? setNumeric(c.v2) : "- -" }}</span>
                  </v-col>
                  <v-col v-if="segment == 1" :class="c.type == 'h' ? 'text-center' : 'text-right'">
                    <v-text-field
                      v-if="c.type == 'f'"
                      reverse
                      block
                      flat
                      solo
                      background-color="#ffffff"
                      class="calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                      hide-details
                      dense
                      v-model="brok_per4"
                      value="0.03"
                      hide-spin-buttons
                      @keyup="intraday4()"
                      type="number"
                      min="0"
                    ></v-text-field>
                    <span v-else class="txt-black pr-2" :class="c.type == 'h' ? 'fs-18 font-weight-medium' : 'fs-14 font-weight-light'">{{ c.v4 != "-" ? setNumeric(c.v4) : "- -" }}</span>
                  </v-col>
                  <v-col v-if="segment == 2" :class="c.type == 'h' ? 'text-center' : 'text-right'">
                    <v-text-field
                      v-if="c.type == 'f'"
                      reverse
                      block
                      flat
                      solo
                      background-color="#ffffff"
                      class="calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                      hide-details
                      dense
                      v-model="brok_per6"
                      value="0.03"
                      hide-spin-buttons
                      @keyup="intraday6()"
                      type="number"
                      min="0"
                    ></v-text-field>
                    <span v-else class="txt-black pr-2" :class="c.type == 'h' ? 'fs-18 font-weight-medium' : 'fs-14 font-weight-light'">{{ c.v6 != "-" ? setNumeric(c.v6) : "- -" }}</span>
                  </v-col>
                  <v-col v-if="segment == 3" :class="c.type == 'h' ? 'text-center' : 'text-right'">
                    <v-text-field
                      v-if="c.type == 'f'"
                      reverse
                      block
                      flat
                      solo
                      background-color="#ffffff"
                      class="calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                      hide-details
                      dense
                      v-model="brok_per8"
                      value="0.03"
                      hide-spin-buttons
                      @keyup="intraday8()"
                      type="number"
                      min="0"
                    ></v-text-field>
                    <span v-else class="txt-black pr-2" :class="c.type == 'h' ? 'fs-18 font-weight-medium' : 'fs-14 font-weight-light'">{{ c.v8 != "-" ? setNumeric(c.v8) : "- -" }}</span>
                  </v-col>

                  <v-col v-if="c.name && segment == 3" :class="c.class" class="fs-14 font-weight-regular">{{ c.name }}</v-col>
                  <v-col v-if="segment == 3" :class="c.type == 'h' ? 'text-center' : 'text-right'">
                    <v-text-field
                      v-if="c.type == 'f'"
                      reverse
                      block
                      flat
                      solo
                      background-color="#ffffff"
                      class="calc-field brk-calc-field ml-auto elevation-0 rounded-pill"
                      hide-details
                      dense
                      v-model="brok_per9"
                      value="0.03"
                      hide-spin-buttons
                      @keyup="intraday9()"
                      type="number"
                      min="0"
                    ></v-text-field>
                    <span v-else class="txt-black pr-2" :class="c.type == 'h' ? 'fs-18 font-weight-medium' : 'fs-14 font-weight-light'">{{ c.v9 != "-" ? setNumeric(c.v9) : "- -" }}</span>
                  </v-col>
                </v-row>
                <v-divider v-if="c.type != 'h'"></v-divider>
              </div>
            </div>
          </v-card>
        </v-card>
        <p class="px-6 py-3 mb-0 font-weight-light txt-666666">Note : STT Will be higher in case of option exercised & Option Brokerage per lot, single side is Rs. 50</p>
      </v-card>
      <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
        <div class="px-6 px-sm-8 px-md-10 py-6">
          <p class="mb-0 font-weight-bold title">What is brokerage?</p>
          <p class="mb-6 font-weight-light txt-444">A brokerage fee is a fee or commission that a broker charges a client to handle transactions or do specialised work on their behalf. Brokers charge fees for services like buying, selling, consulting, negotiating, and delivery.</p>

          <p class="mb-0 font-weight-bold title">How to calculate brokerage?</p>
          <p class="mb-6 font-weight-light txt-444">
            As we've already said, brokerage charges are the fees that traders pay to brokers for helping them trade. So, investors have to pay brokerage fees both when they buy and when they sell securities. Major brokers charge a fee that is a certain percentage of the value of the trade.
            Depending on the trade value, these percentages could be different. Regardless, the following is the formula for calculating brokerage in the stock market:Brokerage = Number of shares sold/bought x Price of one unit of stock x brokerage percentage
          </p>

          <p class="mb-0 font-weight-bold title">What are the benefits of a brokerage calculator?</p>
          <p class="mb-6 font-weight-light txt-444">
            Investors can compare brokers who charge different brokerage fees by using a brokerage calculator. Brokerage calculators give you correct results right away. It takes into account all the costs of taking a trade. It doesn't cost anything.It is critical for traders who rely heavily on
            timing to execute their transactions, such as intraday traders. They can use an intraday brokerage calculator to make cost analysis easier before buying and selling stocks
          </p>

          <p class="mb-0 font-weight-bold title">How do you use a brokerage calculator?</p>
          <p class="mb-0 font-weight-light txt-444">
            Calculate your stock order's brokerage and other fees.It's an online tool that brokers and other investing platforms put at traders' disposal to make brokerage calculations easier before executing a trade.Input the number of shares or lots, the buying cost and the selling cost to find
            out the brokerage.
          </p>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      segment: 0,
      intraday_buy1: 499,
      intraday_sell1: 501,
      intraday_q1: 100,

      brok_per1: 0.03,
      brok_per2: 0.3,
      brok_per3: 0.03,
      brok_per4: 20,
      brok_per5: 0.03,
      brok_per6: 20,
      brok_per7: 0.03,
      brok_per8: 0.03,
      brok_per9: 0.03,

      brok_type: false,
      brkcalcdata: {
        h: {name: "", type: "h", v1: "Intraday", v2: "Delivery", v3: "Futures", v4: "Options", v5: "Futures", v6: "Options", v7: "(Non-Agri)", v8: "(Agri)", v9: "Options"},
        f: {name: "Brokerage", type: "f", class: "fs-16 font-weight-bold"},
        np: {name: "Net profit", v1: "intraday_netprofit1", v2: "intraday_netprofit2", v3: "intraday_netprofit3", v4: "intraday_netprofit4", v5: "intraday_netprofit5", v6: "intraday_netprofit6", v7: "intraday_netprofit7", v8: "intraday_netprofit8", v9: "intraday_netprofit9"},
        to: {name: "Turnover", v1: "toamt1", v2: "toamt2", v3: "toamt3", v4: "toamt4", v5: "toamt5", v6: "toamt6", v7: "toamt7", v8: "toamt8", v9: "toamt9"},
        brk: {name: "Brokerage", v1: "broker1", v2: "broker2", v3: "broker3", v4: "broker4", v5: "broker5", v6: "broker6", v7: "broker7", v8: "broker8", v9: "broker9"},
        tc: {name: "Transaction charge", v1: "trans_charge1", v2: "trans_charge2", v3: "trans_charge3", v4: "trans_charge4", v5: "trans_charge5", v6: "trans_charge6", v7: "trans_charge7", v8: "trans_charge8", v9: "trans_charge9"},
        // "cmc": { name: 'CM Charges', v1: 'cm_charge1', v2: 'cm_charge2', v3: 'cm_charge3', v4: 'cm_charge4', v5: 'cm_charge5', v6: 'cm_charge6', v7: 'cm_charge7', v8: 'cm_charge8',, v9: 'cm_charge9' },
        gst: {name: "GST", v1: "gst1", v2: "gst2", v3: "gst3", v4: "gst4", v5: "gst5", v6: "gst6", v7: "gst7", v8: "gst8"},
        sc: {name: "SEBI Charge", v1: "sebi_charge1", v2: "sebi_charge2", v3: "sebi_charge3", v4: "sebi_charge4", v5: "sebi_charge5", v6: "sebi_charge6", v7: "sebi_charge7", v8: "sebi_charge8", v9: "sebi_charge9"},
        sd: {name: "Stamp Duty", v1: "stamp_charge1", v2: "stamp_charge2", v3: "stamp_charge3", v4: "stamp_charge4", v5: "stamp_charge5", v6: "stamp_charge6", v7: "stamp_charge7", v8: "stamp_charge8", v9: "stamp_charge9"},
        st: {name: "STT total", v1: "stt_tot1", v2: "stt_tot2", v3: "stt_tot3", v4: "stt_tot4", v5: "-", v6: "-", v7: "-", v8: "-", v9: "-"},
        ct: {name: "CTT Total", v1: "-", v2: "-", v3: "-", v4: "-", v5: "-", v6: "-", v7: "ctt7", v8: "-", v9: "-"},
        tt: {name: "Total tax & charges", v1: "intra_tot1", v2: "intra_tot2", v3: "intra_tot3", v4: "intra_tot4", v5: "intra_tot5", v6: "intra_tot6", v7: "intra_tot7", v8: "intra_tot8", v9: "intra_tot9"},
        be: {name: "Points to breakeven", v1: "be1", v2: "be2", v3: "be3", v4: "be4", v5: "be5", v6: "be6", v7: "be7", v8: "be8", v9: "be9"},
      },
    };
  },
  mounted() {
    this.setBrockerage();
  },

  methods: {
    setNumeric(v) {
      var s = Number(v);
      return Number(v) ? (s >= 10000000 ? `${(s / 10000000).toFixed(2)} Cr` : s >= 100000 ? `${(s / 100000).toFixed(2)} L` : s) : v;
    },
    setBrockerage() {
      if (this.brok_type) {
        this.brok_per1 = 0.03;
        this.brok_per2 = 0.3;
        this.brok_per3 = 0.03;
        this.brok_per4 = 0.03;
        this.brok_per5 = 0.03;
        this.brok_per6 = 0.03;
        this.brok_per7 = 0.03;
        this.brok_per8 = 0.03;
        this.brok_per9 = 0.03;
      } else {
        this.brok_per1 = 20;
        this.brok_per2 = 20;
        this.brok_per3 = 20;
        this.brok_per4 = 20;
        this.brok_per5 = 20;
        this.brok_per6 = 20;
        this.brok_per7 = 20;
        this.brok_per8 = 20;
        this.brok_per9 = 20;
      }
      this.setAllfun();
    },
    setAllfun() {
      if (this.segment == 0) {
        this.intraday1();
        this.intraday2();
      }
      if (this.segment == 1) {
        this.intraday3();
        this.intraday4();
      }
      if (this.segment == 2) {
        this.intraday5();
        this.intraday6();
      }
      if (this.segment == 3) {
        this.intraday7();
        this.intraday8();
        this.intraday9();
      }
    },

    intraday1() {
      var intradays_buy1 = parseFloat(this.intraday_buy1);
      var intradays_sell1 = parseFloat(this.intraday_sell1);
      var intradays_q = parseFloat(this.intraday_q1);
      var eq_buyamt = parseFloat((intradays_buy1 * intradays_q * 0.003) / 100).toFixed(2);
      var brkis = this.brok_per1 > 0 ? this.brok_per1 : 0;
      if ((intradays_buy1 + intradays_sell1) * intradays_q) {
        this.brkcalcdata.to.v1 = ((parseFloat(intradays_buy1) + parseFloat(intradays_sell1)) * parseFloat(intradays_q)).toFixed(2);
        this.brkcalcdata.brk.v1 = this.brok_type ? ((this.brkcalcdata.to.v1 * brkis) / 100).toFixed(2) : brkis;
        this.brkcalcdata.st.v1 = ((intradays_sell1 * intradays_q * 0.025) / 100).toFixed(2);
        this.brkcalcdata.tc.v1 = ((this.brkcalcdata.to.v1 * 0.00375) / 100).toFixed(2);
        // this.brkcalcdata.cmc.v1 = ((this.brkcalcdata.to.v1 * 0.0011 / 100).toFixed(2));
        this.brkcalcdata.sc.v1 = ((this.brkcalcdata.to.v1 * 0.0001) / 100).toFixed(2);
        this.brkcalcdata.gst.v1 = (((parseFloat(this.brkcalcdata.brk.v1) + parseFloat(this.brkcalcdata.tc.v1) + parseFloat(this.brkcalcdata.sc.v1)) * 18) / 100).toFixed(2);
        this.brkcalcdata.sd.v1 = parseFloat(eq_buyamt).toFixed(2);
        var eq_totamt = (parseFloat(this.brkcalcdata.brk.v1) + parseFloat(this.brkcalcdata.st.v1) + parseFloat(this.brkcalcdata.tc.v1) + parseFloat(this.brkcalcdata.gst.v1) + parseFloat(this.brkcalcdata.sc.v1)).toFixed(2);
        this.brkcalcdata.tt.v1 = (parseFloat(eq_totamt) + parseFloat(eq_buyamt)).toFixed(2);
        this.brkcalcdata.np.v1 = (intradays_sell1 * intradays_q - intradays_buy1 * intradays_q - this.brkcalcdata.tt.v1).toFixed(2);
        this.brkcalcdata.be.v1 = (this.brkcalcdata.tt.v1 / intradays_q).toFixed(2);
      } else {
        this.brkcalcdata.to.v1 = 0;
        this.brkcalcdata.brk.v1 = 0;
        this.brkcalcdata.st.v1 = 0;
        this.brkcalcdata.tc.v1 = 0;
        // this.brkcalcdata.cmc.v1 = 0;
        this.brkcalcdata.gst.v1 = 0;
        this.brkcalcdata.sc.v1 = 0;
        this.brkcalcdata.tt.v1 = 0;
        this.brkcalcdata.np.v1 = 0;
      }
    },

    intraday2() {
      var intradays_buy = parseFloat(this.intraday_buy1);
      var intradays_sell = parseFloat(this.intraday_sell1);
      var intradays_q = parseFloat(this.intraday_q1);
      var eq_buyamt1 = parseFloat((intradays_buy * intradays_q * 0.015) / 100).toFixed(2);
      var brkis = this.brok_per2 > 0 ? this.brok_per2 : 0;
      if ((intradays_buy + intradays_sell) * intradays_q) {
        this.brkcalcdata.to.v2 = ((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2);
        this.brkcalcdata.brk.v2 = this.brok_type ? ((this.brkcalcdata.to.v2 * brkis) / 100).toFixed(2) : brkis;
        this.brkcalcdata.st.v2 = ((this.brkcalcdata.to.v2 * 0.1) / 100).toFixed(2);
        this.brkcalcdata.tc.v2 = ((this.brkcalcdata.to.v2 * 0.00375) / 100).toFixed(2);
        // this.brkcalcdata.cmc.v2 = ((this.brkcalcdata.to.v2 * 0.0011 / 100).toFixed(2));
        this.brkcalcdata.sc.v2 = parseFloat((this.brkcalcdata.to.v2 * 0.0001) / 100).toFixed(2);
        this.brkcalcdata.gst.v2 = (((parseFloat(this.brkcalcdata.brk.v2) + parseFloat(this.brkcalcdata.tc.v2) + parseFloat(this.brkcalcdata.sc.v2)) * 18) / 100).toFixed(2);
        this.brkcalcdata.sd.v2 = parseFloat(eq_buyamt1).toFixed(2);
        //$("#intra_tot2").html((parseFloat($("#broker2").html())+parseFloat($("#stt_tot2").html())+parseFloat($("#trans_charge2").html())+parseFloat($("#cm_charge2").html())+parseFloat($("#gst2").html())+parseFloat($("#sebi_charge2").html())).toFixed(2));
        var eq_totamt1 = (parseFloat(this.brkcalcdata.brk.v2) + parseFloat(this.brkcalcdata.st.v2) + parseFloat(this.brkcalcdata.tc.v2) + parseFloat(this.brkcalcdata.gst.v2) + parseFloat(this.brkcalcdata.sc.v2)).toFixed(2);
        this.brkcalcdata.tt.v2 = (parseFloat(eq_totamt1) + parseFloat(eq_buyamt1)).toFixed(2);
        this.brkcalcdata.np.v2 = (intradays_sell * intradays_q - intradays_buy * intradays_q - this.brkcalcdata.tt.v2).toFixed(2);
        this.brkcalcdata.be.v2 = (this.brkcalcdata.tt.v2 / intradays_q).toFixed(2);
      } else {
        this.brkcalcdata.to.v2 = 0;
        this.brkcalcdata.brk.v2 = 0;
        this.brkcalcdata.st.v2 = 0;
        this.brkcalcdata.tc.v2 = 0;
        // this.brkcalcdata.cmc.v2 = 0;
        this.brkcalcdata.gst.v2 = 0;
        this.brkcalcdata.sc.v2 = 0;
        this.brkcalcdata.tt.v2 = 0;
        this.brkcalcdata.np.v2 = 0;
      }
    },

    intraday3() {
      var intradays_buy = parseFloat(this.intraday_buy1);
      var intradays_sell = parseFloat(this.intraday_sell1);
      var intradays_q = parseFloat(this.intraday_q1);
      var fno_buyamt = parseFloat((intradays_buy * intradays_q * 0.002) / 100).toFixed(2);
      var brkis = this.brok_per3 > 0 ? this.brok_per3 : 0;
      if ((intradays_buy + intradays_sell) * intradays_q) {
        this.brkcalcdata.to.v3 = ((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2);
        this.brkcalcdata.brk.v3 = this.brok_type ? ((this.brkcalcdata.to.v3 * brkis) / 100).toFixed(2) : brkis;
        this.brkcalcdata.st.v3 = ((intradays_sell * intradays_q * 0.020) / 100).toFixed(2);
        this.brkcalcdata.tc.v3 = ((this.brkcalcdata.to.v3 * 0.0018) / 100).toFixed(2);
        this.brkcalcdata.sc.v3 = parseFloat((this.brkcalcdata.to.v3 * 0.0001) / 100).toFixed(2);
        this.brkcalcdata.sd.v3 = parseFloat(fno_buyamt).toFixed(2);
        // this.brkcalcdata.cmc.v3 = ((this.brkcalcdata.to.v3 * 0.0011 / 100).toFixed(2));
        this.brkcalcdata.gst.v3 = (((parseFloat(this.brkcalcdata.brk.v3) + parseFloat(this.brkcalcdata.tc.v3) + parseFloat(this.brkcalcdata.sc.v3)) * 18) / 100).toFixed(2);
        //$("#intra_tot3").html((parseFloat($("#broker3").html())+parseFloat($("#stt_tot3").html())+parseFloat($("#trans_charge3").html())+parseFloat($("#cm_charge3").html())+parseFloat($("#gst3").html())+parseFloat($("#sebi_charge3").html())).toFixed(2));
        var fno_totamt = (parseFloat(this.brkcalcdata.brk.v3) + parseFloat(this.brkcalcdata.st.v3) + parseFloat(this.brkcalcdata.tc.v3) + parseFloat(this.brkcalcdata.gst.v3) + parseFloat(this.brkcalcdata.sc.v3)).toFixed(2);

        this.brkcalcdata.tt.v3 = (parseFloat(fno_totamt) + parseFloat(fno_buyamt)).toFixed(2);
        this.brkcalcdata.np.v3 = (intradays_sell * intradays_q - intradays_buy * intradays_q - this.brkcalcdata.tt.v3).toFixed(2);
        // $("#intraday_netprofit3").html(((intraday_sell*intraday_q)-(intraday_buy*intraday_q)-$("#intra_tot3").html()).toFixed(2));
        this.brkcalcdata.be.v3 = (this.brkcalcdata.tt.v3 / intradays_q).toFixed(2);
      } else {
        this.brkcalcdata.to.v3 = 0;
        this.brkcalcdata.brk.v3 = 0;
        this.brkcalcdata.st.v3 = 0;
        this.brkcalcdata.tc.v3 = 0;
        // this.brkcalcdata.cmc.v3 = 0;
        this.brkcalcdata.gst.v3 = 0;
        this.brkcalcdata.sc.v3 = 0;
        this.brkcalcdata.tt.v3 = 0;
        this.brkcalcdata.np.v3 = 0;
      }
    },

    intraday4() {
      var intradays_buy = parseFloat(this.intraday_buy1);
      var intradays_sell = parseFloat(this.intraday_sell1);
      var intradays_q = parseFloat(this.intraday_q1);
      var fno_buyamt1 = parseFloat((intradays_buy * intradays_q * 0.003) / 100).toFixed(2);
      var brkis = this.brok_per4 > 0 ? this.brok_per4 : 0;
      ///console.log("jdchgskj", intradays_buy, intradays_sell, intradays_q, fno_buyamt1)
      if ((intradays_buy + intradays_sell) * intradays_q) {
        this.brkcalcdata.to.v4 = ((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2);
        this.brkcalcdata.brk.v4 = this.brok_type ? ((this.brkcalcdata.to.v4 * brkis) / 100).toFixed(2) : brkis;
        this.brkcalcdata.st.v4 = ((intradays_sell * intradays_q * 0.1) / 100).toFixed(2);
        this.brkcalcdata.tc.v4 = ((this.brkcalcdata.to.v4 * 0.0350) / 100).toFixed(2);
        this.brkcalcdata.sc.v4 = parseFloat((this.brkcalcdata.to.v4 * 0.0001) / 100).toFixed(2);
        // this.brkcalcdata.cmc.v4 = ((this.brkcalcdata.to.v4 * 0.02 / 100).toFixed(2));
        this.brkcalcdata.gst.v4 = (((parseFloat(this.brkcalcdata.brk.v4) + parseFloat(this.brkcalcdata.tc.v4) + parseFloat(this.brkcalcdata.sc.v4)) * 18) / 100).toFixed(2);
        this.brkcalcdata.sd.v4 = parseFloat(fno_buyamt1).toFixed(2);
        //$("#intra_tot4").html((parseFloat($("#broker4").html())+parseFloat($("#stt_tot4").html())+parseFloat($("#trans_charge4").html())+parseFloat($("#cm_charge4").html())+parseFloat($("#gst4").html())+parseFloat($("#sebi_charge4").html())).toFixed(2));
        var fno_totamt1 = (parseFloat(this.brkcalcdata.brk.v4) + parseFloat(this.brkcalcdata.st.v4) + parseFloat(this.brkcalcdata.tc.v4) + parseFloat(this.brkcalcdata.gst.v4) + parseFloat(this.brkcalcdata.sc.v4)).toFixed(2);
        this.brkcalcdata.tt.v4 = (parseFloat(fno_totamt1) + parseFloat(fno_buyamt1)).toFixed(2);
        this.brkcalcdata.np.v4 = (intradays_sell * intradays_q - intradays_buy * intradays_q - this.brkcalcdata.tt.v4).toFixed(2);
        this.brkcalcdata.be.v4 = (this.brkcalcdata.tt.v4 / intradays_q).toFixed(2);
      } else {
        console.log("ddss e");
        this.brkcalcdata.to.v4 = 0;
        this.brkcalcdata.brk.v4 = 0;
        this.brkcalcdata.st.v4 = 0;
        this.brkcalcdata.tc.v4 = 0;
        // this.brkcalcdata.cmc.v4 = 0;
        this.brkcalcdata.gst.v4 = 0;
        this.brkcalcdata.sc.v4 = 0;
        this.brkcalcdata.tt.v4 = 0;
        this.brkcalcdata.np.v4 = 0;
      }
    },
    intraday5() {
      var intradays_buy = parseFloat(this.intraday_buy1);
      var intradays_sell = parseFloat(this.intraday_sell1);
      var intradays_q = parseFloat(this.intraday_q1);
      var cur_buyamt = parseFloat((intradays_buy * intradays_q * 0.0001) / 100).toFixed(2);
      var brkis = this.brok_per5 > 0 ? this.brok_per5 : 0;

      if ((intradays_buy + intradays_sell) * intradays_q) {
        this.brkcalcdata.to.v5 = ((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2);
        this.brkcalcdata.brk.v5 = this.brok_type ? ((this.brkcalcdata.to.v5 * brkis) / 100).toFixed(2) : brkis;
        this.brkcalcdata.tc.v5 = ((this.brkcalcdata.to.v5 * 0.0004) / 100).toFixed(2);
        this.brkcalcdata.sc.v5 = parseFloat((this.brkcalcdata.to.v5 * 0.0001) / 100).toFixed(2);
        // this.brkcalcdata.cmc.v5 = ((this.brkcalcdata.to.v5 * 0.0011 / 100).toFixed(2));
        this.brkcalcdata.gst.v5 = (((parseFloat(this.brkcalcdata.brk.v5) + parseFloat(this.brkcalcdata.tc.v5) + parseFloat(this.brkcalcdata.sc.v5)) * 18) / 100).toFixed(2);
        this.brkcalcdata.sd.v5 = parseFloat(cur_buyamt).toFixed(2);
        //$((parseFloat($("#broker5").html()))+(parseFloat($("#trans_charge5").html()))+(parseFloat($("#cm_charge5").html()))+(parseFloat($("#gst5").html()))+(parseFloat($("#sebi_charge5").html()))).toFixed(2)("#intra_tot5").html();
        var cur_totamt = (parseFloat(this.brkcalcdata.brk.v5) + parseFloat(this.brkcalcdata.tc.v5) + parseFloat(this.brkcalcdata.gst.v5) + parseFloat(this.brkcalcdata.sc.v5)).toFixed(2);
        this.brkcalcdata.tt.v5 = (parseFloat(cur_totamt) + parseFloat(cur_buyamt)).toFixed(2);
        this.brkcalcdata.np.v5 = (intradays_sell * intradays_q - intradays_buy * intradays_q - this.brkcalcdata.tt.v5).toFixed(2);
        this.brkcalcdata.be.v5 = (this.brkcalcdata.tt.v5 / intradays_q).toFixed(2);
      } else {
        this.brkcalcdata.to.v5 = 0;
        this.brkcalcdata.brk.v5 = 0;
        this.brkcalcdata.tc.v5 = 0;
        // this.brkcalcdata.cmc.v5 = 0;
        this.brkcalcdata.gst.v5 = 0;
        this.brkcalcdata.sc.v5 = 0;
        this.brkcalcdata.tt.v5 = 0;
        this.brkcalcdata.np.v5 = 0;
      }
    },
    intraday6() {
      var intradays_buy = parseFloat(this.intraday_buy1);
      var intradays_sell = parseFloat(this.intraday_sell1);
      var intradays_q = parseFloat(this.intraday_q1);
      var cur_buyamt1 = parseFloat((intradays_buy * intradays_q * 0.0001) / 100).toFixed(2);
      var brkis = this.brok_per6 > 0 ? this.brok_per6 : 0;
      if ((intradays_buy + intradays_sell) * intradays_q) {
        this.brkcalcdata.to.v6 = ((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2);
        //$("#broker6").html(($("#toamt6").html()*$("#brok_per6").val()/100).toFixed(2));
        this.brkcalcdata.brk.v6 = this.brok_type ? ((this.brkcalcdata.to.v6 * brkis) / 100).toFixed(2) : brkis;
        this.brkcalcdata.tc.v6 = ((this.brkcalcdata.to.v6 * 0.031) / 100).toFixed(2);
        // this.brkcalcdata.cmc.v6 = ((this.brkcalcdata.to.v6 * 0.02 / 100).toFixed(2));
        this.brkcalcdata.sc.v6 = parseFloat((this.brkcalcdata.to.v6 * 0.0001) / 100).toFixed(2);
        this.brkcalcdata.gst.v6 = (((parseFloat(this.brkcalcdata.brk.v6) + parseFloat(this.brkcalcdata.tc.v6) + parseFloat(this.brkcalcdata.sc.v6)) * 18) / 100).toFixed(2);
        this.brkcalcdata.sd.v6 = parseFloat(cur_buyamt1).toFixed(2);
        var cur_totamt1 = (parseFloat(this.brkcalcdata.brk.v6) + parseFloat(this.brkcalcdata.tc.v6) + parseFloat(this.brkcalcdata.gst.v6) + parseFloat(this.brkcalcdata.sc.v6)).toFixed(2);
        this.brkcalcdata.tt.v6 = (parseFloat(cur_totamt1) + parseFloat(cur_buyamt1)).toFixed(2);
        this.brkcalcdata.np.v6 = (intradays_sell * intradays_q - intradays_buy * intradays_q - this.brkcalcdata.tt.v6).toFixed(2);
        this.brkcalcdata.be.v6 = (this.brkcalcdata.tt.v6 / intradays_q).toFixed(2);
      } else {
        this.brkcalcdata.to.v6 = 0;
        this.brkcalcdata.brk.v6 = 0;
        this.brkcalcdata.tc.v6 = 0;
        // this.brkcalcdata.cmc.v6 = (0);
        this.brkcalcdata.gst.v6 = 0;
        this.brkcalcdata.sc.v6 = 0;
        this.brkcalcdata.tt.v6 = 0;
        this.brkcalcdata.np.v6 = 0;
      }
    },
    intraday7() {
      var intraday_buy = parseFloat(this.intraday_buy1);
      var intraday_sell = parseFloat(this.intraday_sell1);
      var intradays_q = parseFloat(this.intraday_q1);
      var comm_buyamt = parseFloat((intraday_buy * intradays_q * 0.002) / 100).toFixed(2);
      var brkis = this.brok_per7 > 0 ? this.brok_per7 : 0;
      if ((intraday_buy + intraday_sell) * intradays_q) {
        this.brkcalcdata.to.v7 = ((parseFloat(intraday_buy) + parseFloat(intraday_sell)) * parseFloat(intradays_q)).toFixed(2);
        this.brkcalcdata.brk.v7 = this.brok_type ? ((this.brkcalcdata.to.v7 * brkis) / 100).toFixed(2) : brkis;
        this.brkcalcdata.tc.v7 = ((this.brkcalcdata.to.v7 * 0.0021) / 100).toFixed(2);
        // this.brkcalcdata.cmc.v7 = ((this.brkcalcdata.to.v7 * 0.0011 / 100).toFixed(2));
        this.brkcalcdata.sc.v7 = parseFloat((this.brkcalcdata.to.v7 * 0.0001) / 100).toFixed(2);
        this.brkcalcdata.gst.v7 = (((parseFloat(this.brkcalcdata.brk.v7) + parseFloat(this.brkcalcdata.tc.v7) + parseFloat(this.brkcalcdata.sc.v7)) * 18) / 100).toFixed(2);
        this.brkcalcdata.ct.v7 = ((intraday_sell * intradays_q * 0.01) / 100).toFixed(2);
        this.brkcalcdata.sd.v7 = parseFloat(comm_buyamt).toFixed(2);
        //$("#intra_tot7").html(((parseFloat($("#broker7").html()))+(parseFloat($("#trans_charge7").html()))+(parseFloat($("#cm_charge7").html()))+(parseFloat($("#gst7").html()))+(parseFloat($("#ctt7").html()))+(parseFloat($("#sebi_charge7").html()))).toFixed(2));
        var comm_totamt = (parseFloat(this.brkcalcdata.brk.v7) + parseFloat(this.brkcalcdata.tc.v7) + parseFloat(this.brkcalcdata.gst.v7) + parseFloat(this.brkcalcdata.ct.v7) + parseFloat(this.brkcalcdata.sc.v7)).toFixed(2);
        this.brkcalcdata.tt.v7 = (parseFloat(comm_totamt) + parseFloat(comm_buyamt)).toFixed(2);
        this.brkcalcdata.np.v7 = (intraday_sell * intradays_q - intraday_buy * intradays_q - this.brkcalcdata.tt.v7).toFixed(2);
        this.brkcalcdata.be.v7 = (this.brkcalcdata.tt.v7 / intradays_q).toFixed(2);
      } else {
        this.brkcalcdata.to.v7 = 0;
        this.brkcalcdata.brk.v7 = 0;
        this.brkcalcdata.tc.v7 = 0;
        // this.brkcalcdata.cmc.v7 = (0);
        this.brkcalcdata.gst.v7 = 0;
        this.brkcalcdata.ct.v7 = 0;
        this.brkcalcdata.sc.v7 = 0;
        this.brkcalcdata.tt.v7 = 0;
        this.brkcalcdata.np.v7 = 0;
      }
    },
    intraday8() {
      var intradays_buy = parseFloat(this.intraday_buy1);
      var intradays_sell = parseFloat(this.intraday_sell1);
      var intradays_q = parseFloat(this.intraday_q1);
      var comm_buyamt1 = parseFloat((intradays_buy * intradays_q * 0.003) / 100).toFixed(2);
      var brkis = this.brok_per8 > 0 ? this.brok_per8 : 0;
      if ((intradays_buy + intradays_sell) * intradays_q) {
        this.brkcalcdata.to.v8 = ((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2);
        this.brkcalcdata.brk.v8 = this.brok_type ? ((this.brkcalcdata.to.v8 * brkis) / 100).toFixed(2) : brkis;
        this.brkcalcdata.tc.v8 = ((this.brkcalcdata.to.v8 * 0.0021) / 100).toFixed(2);
        // this.brkcalcdata.cmc.v8 = ((this.brkcalcdata.to.v8 * 0.0011 / 100).toFixed(2));
        this.brkcalcdata.sc.v8 = parseFloat((this.brkcalcdata.to.v8 * 0.0001) / 100).toFixed(2);
        this.brkcalcdata.gst.v8 = (((parseFloat(this.brkcalcdata.brk.v8) + parseFloat(this.brkcalcdata.tc.v8) + parseFloat(this.brkcalcdata.sc.v8)) * 18) / 100).toFixed(2);
        this.brkcalcdata.sd.v8 = parseFloat(comm_buyamt1).toFixed(2);
        //$("#intra_tot8").html(((parseFloat($("#broker8").html()))+(parseFloat($("#trans_charge8").html()))+(parseFloat($("#cm_charge8").html()))+(parseFloat($("#gst8").html()))+(parseFloat($("#sebi_charge8").html()))).toFixed(2));
        var comm_totamt1 = (parseFloat(this.brkcalcdata.brk.v8) + parseFloat(this.brkcalcdata.tc.v8) + parseFloat(this.brkcalcdata.gst.v8) + parseFloat(this.brkcalcdata.sc.v8)).toFixed(2);
        this.brkcalcdata.tt.v8 = (parseFloat(comm_totamt1) + parseFloat(comm_buyamt1)).toFixed(2);
        this.brkcalcdata.np.v8 = (intradays_sell * intradays_q - intradays_buy * intradays_q - this.brkcalcdata.tt.v8).toFixed(2);
        this.brkcalcdata.be.v8 = (this.brkcalcdata.tt.v8 / intradays_q).toFixed(2);
      } else {
        this.brkcalcdata.to.v8 = 0;
        this.brkcalcdata.brk.v8 = 0;
        this.brkcalcdata.tc.v8 = 0;
        // this.brkcalcdata.cmc.v8 = (0);
        this.brkcalcdata.gst.v8 = 0;
        this.brkcalcdata.sc.v8 = 0;
        this.brkcalcdata.tt.v8 = 0;
        this.brkcalcdata.np.v8 = 0;
      }
    },
    intraday9() {
      var intradays_buy = parseFloat(this.intraday_buy1);
      var intradays_sell = parseFloat(this.intraday_sell1);
      var intradays_q = parseFloat(this.intraday_q1);
      var cur_buyamt1 = parseFloat((intradays_buy * intradays_q * 0.003) / 100).toFixed(2);
      var brkis = this.brok_per9 > 0 ? this.brok_per9 : 0;
      if ((intradays_buy + intradays_sell) * intradays_q) {
        this.brkcalcdata.to.v9 = ((parseFloat(intradays_buy) + parseFloat(intradays_sell)) * parseFloat(intradays_q)).toFixed(2);
        //$("#broker9").html(($("#toamt9").html()*$("#brok_per9").val()/100).toFixed(2));
        this.brkcalcdata.brk.v9 = this.brok_type ? ((this.brkcalcdata.to.v9 * brkis) / 100).toFixed(2) : brkis;
        this.brkcalcdata.tc.v9 = ((this.brkcalcdata.to.v9 * 0.05) / 100).toFixed(2);
        // this.brkcalcdata.cmc.v9 = ((this.brkcalcdata.to.v9 * 0.02 / 100).toFixed(2));
        this.brkcalcdata.sc.v9 = parseFloat((this.brkcalcdata.to.v9 * 0.0001) / 100).toFixed(2);
        this.brkcalcdata.gst.v9 = (((parseFloat(this.brkcalcdata.brk.v9) + parseFloat(this.brkcalcdata.tc.v9) + parseFloat(this.brkcalcdata.sc.v9)) * 18) / 100).toFixed(2);
        this.brkcalcdata.ct.v9 = ((intradays_sell * intradays_q * 0.041) / 100).toFixed(2);

        this.brkcalcdata.sd.v9 = parseFloat(cur_buyamt1).toFixed(2);
        var cur_totamt1 = (parseFloat(this.brkcalcdata.brk.v9) + parseFloat(this.brkcalcdata.tc.v9) + parseFloat(this.brkcalcdata.gst.v9) + parseFloat(this.brkcalcdata.ct.v9) + parseFloat(this.brkcalcdata.sc.v9)).toFixed(2);
        this.brkcalcdata.tt.v9 = (parseFloat(cur_totamt1) + parseFloat(cur_buyamt1)).toFixed(2);
        this.brkcalcdata.np.v9 = (intradays_sell * intradays_q - intradays_buy * intradays_q - this.brkcalcdata.tt.v9).toFixed(2);
        this.brkcalcdata.be.v9 = (this.brkcalcdata.tt.v9 / intradays_q).toFixed(2);
      } else {
        this.brkcalcdata.to.v9 = 0;
        this.brkcalcdata.brk.v9 = 0;
        this.brkcalcdata.tc.v9 = 0;
        // this.brkcalcdata.cmc.v9 = (0);
        this.brkcalcdata.gst.v9 = 0;
        this.brkcalcdata.ct.v9 = 0;
        this.brkcalcdata.sc.v9 = 0;
        this.brkcalcdata.tt.v9 = 0;
        this.brkcalcdata.np.v9 = 0;
      }
    },
  },
};
</script>
